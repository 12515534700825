import { useState, useCallback, useMemo } from "react";
import update from "immutability-helper";

export enum FIELD_TYPES {
  TEXT = 1,
  ARRAY = 2,
}

export type FieldDescriptor<T, S, Q = any> = FormFieldsErrorMap<
  keyof T,
  {
    validator: (value: S, formFields: T, additional?: Q) => boolean;
    errorMessage?: string;
    fieldType?: FIELD_TYPES;
  }
>;

type FormFieldsErrorMap<TKey extends keyof any, TValue> = {
  [K in TKey]: TValue;
};

const useFormFields = <S = unknown, T = StringTMap<S>, Q = any>(
  fieldDescriptor: FieldDescriptor<T, S, Q>,
  additional?: Q,
  defaultState?: Partial<T>,
) => {
  const [formState, setFormState] = useState({
    fields: (defaultState || {}) as T,
    errors: {} as FormFieldsErrorMap<keyof T, string>,
  });

  const formFields = formState.fields;
  const formErrors = formState.errors;

  const resetForm = useCallback(() => {
    setFormState({
      fields: (defaultState || {}) as T,
      errors: {} as FormFieldsErrorMap<keyof T, string>,
    });
  }, [defaultState]);

  const setFormFieldValue = useCallback(
    (inputKey: keyof T, value: S, index: number = 0) => {
      const isFieldArray =
        fieldDescriptor[inputKey].fieldType === FIELD_TYPES.ARRAY;

      setFormState((prevState) =>
        update<typeof formState, any>(prevState, {
          errors: {
            $apply: (errors: any) =>
              !!errors[inputKey]
                ? isFieldArray
                  ? // @ts-ignore
                    update(errors, {
                      [inputKey]: {
                        $splice: [[index, 1]],
                      },
                    })
                  : update<any>(errors, {
                      $unset: [inputKey],
                    })
                : errors,
          },
          fields: {
            [inputKey]: {
              $set: isFieldArray
                ? update<any, any>(prevState.fields[inputKey] || [], {
                    $splice: [[index, 1, value]],
                  })
                : value,
            },
          },
        }),
      );
    },
    [fieldDescriptor],
  );

  const setFormFieldError = useCallback(
    (inputKey: keyof T, message: string, index: number = 0) => {
      const isFieldArray =
        fieldDescriptor[inputKey].fieldType === FIELD_TYPES.ARRAY;

      setFormState((prevState) =>
        update<typeof formState, any>(prevState, {
          errors: {
            [inputKey]: {
              $set: isFieldArray
                ? update<any, any>(prevState.errors[inputKey] || [], {
                    $splice: [[index, 1, message]],
                  })
                : message,
            },
          },
        }),
      );
    },
    [fieldDescriptor],
  );

  const removeFormField = useCallback(
    (inputKey: keyof T, index: number = 0) => {
      const isFieldArray =
        fieldDescriptor[inputKey].fieldType === FIELD_TYPES.ARRAY;

      setFormState((prevState) =>
        update<typeof formState, any>(prevState, {
          errors: {
            $apply: (errors: any) =>
              !!errors[inputKey]
                ? isFieldArray
                  ? // @ts-ignore
                    update(errors, {
                      [inputKey]: {
                        $splice: [[index, 1]],
                      },
                    })
                  : update<any>(errors, {
                      $unset: [inputKey],
                    })
                : errors,
          },
          fields: {
            [inputKey]: {
              $set: isFieldArray
                ? update<any, any>(prevState.fields[inputKey] || [], {
                    $splice: [[index, 1]],
                  })
                : undefined,
            },
          },
        }),
      );
    },
    [fieldDescriptor],
  );

  const validateFormField = useCallback(
    (inputKey: keyof T, value: S, index: number = 0) => {
      const fieldDescriptorValidator = fieldDescriptor[inputKey];

      if (!fieldDescriptor) {
        return;
      }

      const isValid = fieldDescriptorValidator.validator(
        value,
        formFields,
        additional,
      );

      if (!isValid && !!fieldDescriptorValidator.errorMessage) {
        setFormFieldError(
          inputKey,
          fieldDescriptorValidator.errorMessage,
          index,
        );

        return false;
      }

      return true;
    },
    [additional, fieldDescriptor, formFields, setFormFieldError],
  );

  const validateForm = useCallback(() => {
    let isValidForm = true;

    Object.keys(fieldDescriptor).forEach((formFieldKey) => {
      const formFieldValue = (formFields as StringAnyMap)[formFieldKey];

      const isValidField = validateFormField(
        formFieldKey as keyof T,
        formFieldValue,
      );

      isValidForm = isValidForm && !!isValidField;
    });

    return isValidForm;
  }, [fieldDescriptor, formFields, validateFormField]);

  const state = useMemo(
    () => ({
      validateFormField,
      validateForm,
      setFormFieldValue,
      setFormFieldError,
      removeFormField,
      resetForm,
      formFields,
      formErrors,
      hasErrors: Object.keys(formErrors).length > 0,
    }),
    [
      validateFormField,
      validateForm,
      setFormFieldValue,
      setFormFieldError,
      removeFormField,
      resetForm,
      formFields,
      formErrors,
    ],
  );

  return state;
};

export default useFormFields;

import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import getConfig from "next/config";
import { isEmail } from "class-validator";

// types
import type Auth from "types/auth";

// clients
import { setAuthToken, setRefreshToken } from "clients/rest";

// constants
import { API_ENDPOINTS } from "constants/api";

// hooks
import useFormFields from "hooks/useFormFields";
import useQuery from "hooks/useQuery";
import useMutation from "hooks/useMutation";
import useUser from "hooks/useUser";
import type { FieldDescriptor } from "hooks/useFormFields";

// icons
import Denied from "../../assets/images/svg/denied.svg";

interface LoginFields {
  email: string;
}

const { publicRuntimeConfig } = getConfig();

export const FIELDS_DESCRIPTOR: FieldDescriptor<
  LoginFields,
  Nullable<string>
> = {
  email: {
    validator: (value) => isEmail(value),
    errorMessage: "Invalid email",
  },
};

const Login = () => {
  const [keyId, setKeyId] = useState<Null<string>>(null);

  const { logout } = useUser();

  const {
    setFormFieldValue,
    validateFormField,
    validateForm,
    formFields,
    formErrors,
    hasErrors,
  } = useFormFields<Nullable<string>, LoginFields>(FIELDS_DESCRIPTOR);

  const {
    mutateAsync: loginUser,
    variables,
    isLoading,
    isSuccess,
  } = useMutation<
    Auth.LoginUserRequest & { csrf: string },
    Auth.LoginUserResponse
  >(API_ENDPOINTS.AUTH.LOGIN);

  const { isError: isOneTapError } = useQuery<
    Auth.OneTapAuthResponse,
    Auth.OneTapAuthRequest
  >(API_ENDPOINTS.AUTH.ONE_TAP, {
    enabled: !!keyId && !!variables?.email,
    refetchIntervalInBackground: true,
    refetchInterval: 4 * 1000, // 4 seconds
    params: {
      email: variables?.email || "",
      keyid: keyId || "",
    },
    onSuccess: (data) => {
      if (data.authToken) {
        setKeyId(null);
        setAuthToken(data.authToken);
        setRefreshToken(data.refreshToken);

        window.location.href = "/overview";
      }
    },
    onError: () => {
      setKeyId(null);
    },
  });

  const isError = isSuccess && isOneTapError;

  const submitForm = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!validateForm()) {
        return;
      }

      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute(publicRuntimeConfig.RECAPTCHA_SITE_KEY, { action: "login" })
          .then(function (token) {
            loginUser({ email: formFields.email, csrf: token })
              .then(({ keyid }) => {
                setKeyId(keyid);
              })
              .catch(console.log);
          });
      });
    },
    [formFields, validateForm],
  );

  useEffect(() => {
    logout(true);
  }, [logout]);

  return (
    <div className="bg-light d-flex align-items-center w-100 min-vh-50 py-5">
      <div
        className="shadow rounded-2 p-5 m-4 m-md-auto mw-25"
        style={{ maxWidth: 450 }}
      >
        <h1 className="h3 mb-3 fw-normal text-center">
          Login to Mayo Business
        </h1>
        {!isError && (
          <>
            {!isSuccess && (
              <Form onSubmit={submitForm}>
                <div className="form-floating">
                  <Form.Control
                    autoFocus
                    id="email"
                    placeholder="Email"
                    type="email"
                    autoComplete="email"
                    isInvalid={!!formErrors.email}
                    onChange={(e) => setFormFieldValue("email", e.target.value)}
                    onBlur={(e) => validateFormField("email", e.target.value)}
                  />
                  <Form.Label htmlFor="email">Email address</Form.Label>
                </div>
                <Button
                  size="lg"
                  variant="success"
                  className="w-100 mt-4 mb-2"
                  type="submit"
                  disabled={hasErrors || isLoading}
                >
                  {isLoading && (
                    <Spinner
                      animation="border"
                      size="sm"
                      role="status"
                      className="me-2"
                    />
                  )}
                  Send
                </Button>
                <Form.Text>
                  Make sure that you have an active Mayo Business account
                  signed-in on a device.
                </Form.Text>
              </Form>
            )}
            {isSuccess && (
              <div className="d-flex align-items-center flex-column">
                <Spinner animation="border" role="status" />
                <p className="mt-4 text-center">
                  We've sent a notification to your mobile device. Please tap on
                  it for further instructions.
                </p>
              </div>
            )}
          </>
        )}
        {isError && (
          <div className="w-100 text-center">
            <Denied className="p-5 w-75 h-75 align-self-center" />
            <Alert variant="danger">Your login request was denied</Alert>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Login);
